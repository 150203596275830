<template>
  <div class="scoreDetail">

    <div class="flex-box">
      <div class="left">
        <!-- <el-button @click="addTask" type="primary">新增任务</el-button> -->
        <!-- <el-button type="info">导出</el-button> -->
        <!-- <el-button type="primary">排名</el-button> -->
      </div>
      <div class="flex-box">
        <el-select v-model="query.status" clearable placeholder="请选择状态">
            <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
        </el-select>
        <el-input
          placeholder="请输入教师姓名"
          class="picker"
          v-model="query.name"
        ></el-input>
        <el-button type="primary" class="picker" 
          >查 询</el-button
        >
      </div>
    </div>

    <el-table :data="teacherDetailList" style="width: 100%">
      <el-table-column
        label="被评分教师"
        prop="tea_name"
        align="center"
      ></el-table-column>
      <el-table-column
        label="状态"
        prop="statusStr"
        align="center"
      ></el-table-column>
      <el-table-column
        label="资料"
        prop="url"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" width="300px" prop="course" align="center">
        <template slot-scope="scope">
          <el-button size="mini" v-if="scope.row.status === 1" type="primary" @click="score(scope.row)"
            >评分
          </el-button>
          <el-button size="mini" v-if="scope.row.status === 2" type="success" @click="look(scope.row)"
            >查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      layout="prev,pager,next"
      background
      :page-count="page.pageCount"
      :page-size="page.currentPage"
      @current-change="queryTask"
    ></el-pagination>

    <el-dialog
        :visible.sync="scoreDialogVisible"
        width="600px"
      >
        <div class="center">
          <el-form
            :model="scoreForm"
            ref="scoreFormRef"
            label-width="120px"
            :rules="scoreFormRules"
          >
            <el-form-item label="评分：" prop="score">
              <el-input
                :disabled="!buttonHidden"
                type="text"
                v-model="scoreForm.score"
              ></el-input>
            </el-form-item>
            <el-form-item label="评价：" prop="opinion">
              <el-input
                :disabled="!buttonHidden"
                type="textarea"
                v-model="scoreForm.opinion"
              ></el-input>
            </el-form-item>

          </el-form>
        </div>

        <!-- 确认与取消 -->
        <span v-if="buttonHidden" slot="footer" class="dialog-footer">
          <el-button @click="scoreDialogVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="scoreConfirm">确 定</el-button>
        </span>
    </el-dialog>

    
  </div>
</template>

<script>
import classSelector from "@/components/selectors/classSelector";
import termSelector from "@/components/selectors/termSelector";

export default {
  components: { classSelector, termSelector },
  name: "scoreDetail",
  data() {
    return {
        page: { pageCount: 1, currentPage: 0 },
        score_id:Number(this.$route.query.score_id),
        rater_id:Number(this.$route.query.rater_id),
        begin_time:this.$route.query.begin_time,
        end_time:this.$route.query.end_time,
        teacherDetailList:[],
        // 评分表单
        scoreForm:{
            score_id:'',
            rater_id:'',
            tea_id:'',
            score:'',
            opinion:''
        },
        // 评分验证规则对象
        scoreFormRules:{
            score:[{required:true,message:'请输入评分',trigger:'blur'}],
            // opinion:[{required:true,message:'请输入意见',trigger:'blur'}],
        },
        // 评分对话框显示与隐藏
        scoreDialogVisible:false,
        // 按钮显示与隐藏
        buttonHidden:true,
        


        // 状态列表
        statusList:[
            {value:0,label:'全部'},
            {value:1,label:'未评分'},
            {value:2,label:'已评分'},
            {value:3,label:'未上传'},
            {value:4,label:'评分中'},
        ],
        // 查询
        query:{
            status:'',
            name:''
        },
        

    };
  },
  created() {
    this.taskDetail()
    
  },
  methods: {
    // 详情
    taskDetail(){
      this.$post(`/base/queryScoringSystemTeaListByRater.do`,{
          score_id:this.score_id,
          rater_id:this.rater_id
        })
      .then(res=>{
        console.log(res)
        res.data.forEach(item=>{
          if(item.url != null){
            item.url = this.$fileUploadDomain + item.url
          }
        })
        this.teacherDetailList = res.data
        
      })
    },
    score(row){
      // console.log(this.getTime(this.begin_time,this.end_time))
      // console.log(this.begin_time,this.end_time)
      // return
      if(row.url == null && row.type === 2){
        this.$message({
          type:'info',
          message:'需要上传资料才能评分！'
        })
      }else{
        if(this.getTime(this.begin_time,this.end_time)){
          this.buttonHidden = true
          this.scoreDialogVisible = true
          this.$nextTick(()=>{
              this.scoreForm.score_id = row.score_id
              this.scoreForm.rater_id = row.rater_id
              this.scoreForm.tea_id = row.tea_id
              this.scoreForm.score = ''
              this.scoreForm.opinion = ''
          })
        }else{
          this.$message({
            type:'info',
            message:'当前时间不在评分时间范围内！'
          })
        }
      }
      // if(this.getTime(this.begin_time,this.end_time)){
      //   this.buttonHidden = true
      //   this.scoreDialogVisible = true
      //   this.$nextTick(()=>{
      //       this.scoreForm.score_id = row.score_id
      //       this.scoreForm.rater_id = row.rater_id
      //       this.scoreForm.tea_id = row.tea_id
      //       this.scoreForm.score = ''
      //       this.scoreForm.opinion = ''
      //   })
      // }else{
      //   this.$message({
      //     type:'info',
      //     message:'当前时间不在评分时间范围内！'
      //   })
      // }
        
    },
    // 评分确定
    scoreConfirm(){
        // console.log(this.scoreForm)
        // return
        this.$refs.scoreFormRef.validate(validate=>{
            if(validate){
                let data = {
                    score_id:this.scoreForm.score_id,
                    rater_id:this.scoreForm.rater_id,
                    tea_id:this.scoreForm.tea_id,
                    score:this.scoreForm.score,
                    opinion:this.scoreForm.opinion,
                }
                this.$post(`/base/updateScoringSystemByRater.do`,data)
                .then(res=>{
                    this.$message({
                        type:'success',
                        message:'评分成功！'
                    })
                    this.scoreDialogVisible = false
                    this.taskDetail()
                }).catch(err=>{
                    this.$message({
                        type:'error',
                        message:'评分失败！'
                    })
                })
            }
        })

        

    },
    // 查看
    look(row){
        this.buttonHidden = false
        this.scoreDialogVisible = true
        this.$nextTick(()=>{
            this.scoreForm.score_id = row.score_id
            this.scoreForm.rater_id = row.rater_id
            this.scoreForm.tea_id = row.tea_id
            this.scoreForm.score = row.score
            this.scoreForm.opinion = row.opinion
        })
    },
    // 判断当天时间是否属于评分时间范围内
    getTime(startTime,endTime){
      // 当天时间
      let currentTime = new Date().getTime()
      let begin_time = new Date(startTime).getTime()
      let end_time = new Date(endTime).getTime()
      if(currentTime >= begin_time && currentTime <= end_time+86400000){
        return true
      }else{
        return false
      }

    },

  },
};
</script>

<style scoped>
.input-box {
  width: 180px;
  margin: 0 5px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker {
  margin-left: 10px;
}
</style>
